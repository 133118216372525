import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isLogin, setToken, getUserInfo, setUserInfo } from "../services/index";
//import jwt_decode from "jwt-decode";

import { AppConfig } from "../../AppConfig";
export const loginUser = createAsyncThunk(
  "users/loginUser",
  async ({ username, passwordvalue }, thunkAPI) => {
    try {
      //console.log(`username value =>> ${username}`)
      //console.log(`password value =>> ${passwordvalue}`)

      const apiURL = `${AppConfig.apiURL}/auth/login`;
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          r_user: username,
          r_password: passwordvalue,
        }),
      });
      let data = await response.json();
      //console.log("data", data);

      if (response.status === 200) {
        //console.log(data.user.firstName);
        setToken(data.token);
        setUserInfo(data.user);
        return {
          ...data,
          username: username,
        };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

function buildInitialData() {
  //const tokenValue = localStorage.getItem(AppConfig.storageNameToken);
  //console.log(`Token = ${tokenValue}`);
  //let isLoginValue = isLogin();
  //const userNameValue = getUsername();
  //if (tokenValue === "" || tokenValue === null) isLoginValue = false;

  //var decoded = jwt_decode(tokenValue);
  //console.log(decoded);
  const initialState = {
    userinfo: getUserInfo(),
    isLogin: isLogin(),
    isSuccess: false,
    isError: false,
    errorMessage: "",
  };
  return initialState;
}

const initialState = buildInitialData();

export const userInfoSlice = createSlice({
  name: "userinfo",
  initialState,
  reducers: {
    setIsLogin: (state, action) => {
      state.isLogin = action.payload;
    },
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = "";
      return state;
    },
  },
  extraReducers: {
    [loginUser.fulfilled]: (state, { payload }) => {
      //console.log(payload.user);
      state.userinfo = payload.user;
      state.isFetching = false;
      state.isSuccess = true;
      state.isLogin = true;
      return state;
    },
    [loginUser.rejected]: (state, { payload }) => {
      //console.log("payload", payload);
      state.userinfo = null;
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const { setIsLogin, clearState } = userInfoSlice.actions;
export const selectIsLoginState = (state) => state.userinfo.isLogin;
export const userSelector = (state) => state.userinfo;

export default userInfoSlice.reducer;
