export const datetimeToString = (value) => {
  //var options = { year: 'numeric', month: 'long', day: 'numeric' };
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  return new Date(value).toLocaleDateString("en-US", options);
};
export const datetime2ToString = (value) => {
  //console.log(value);
  //var options = { year: 'numeric', month: 'long', day: 'numeric' };
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  const reformat = new Date(value).toLocaleDateString("en-US", options);
  // console.log(reformat);
  const result = reformat.replace(",", "");
  return result;
};
export const numberFormat = (number) => {
  return number.toLocaleString();
};
export const isNullEmpty = (value) => {
  if (value !== "" && value !== null && value !== undefined) {
    return false;
  } else {
    return true;
  }
};
export const handleTextNumberKeyDownCommon = (event) => {
  const specialKeys = [
    "Control",
    "Backspace",
    "Tab",
    "End",
    "Home",
    "Left",
    "ArrowLeft",
    "Right",
    "ArrowRight",
    "Delete",
    ",",
    ".",
  ];
  const regex = new RegExp(/^[0-9]+(?:\.\d{0,2})?$/g);
  if (specialKeys.indexOf(event.key) !== -1) {
    return;
  }
  if (
    (event.keyCode === 67 && event.ctrlKey === true) ||
    (event.keyCode === 86 && event.ctrlKey === true)
  ) {
    return;
  }

  let current: string = event.target.value;
  let next: string = current.concat(event.key);
  if (next && !String(next).match(regex)) {
    event.preventDefault();
  }
};

export const toCSVHeader = (cols) => {
  var headers = cols
    .filter((c) => (!c.name ? false : true))
    .map((c) => {
      var header = {
        label: c.name,
        key: c.selector,
      };
      return header;
    });
  console.log("headers: ", headers);
};
