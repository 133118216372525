import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import userinfoReducer from "./@core/reducers/userInfoSlice";

export default configureStore({
  reducer: {
    counter: counterReducer,
    userinfo: userinfoReducer,
  },
});
