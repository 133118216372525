import logo from "./logo.svg";
import React from "react";

import { BrowserRouter, Switch } from "react-router-dom";
import PublicRoute from "./app/@core/components/PublicRoute";
import UserRegister from "./app/screens/master/user/UserRegister";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute
          restricted={true}
          component={UserRegister}
          path="/"
          exact
        />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
