import React from "react";

export default function Label({ text, required }) {
  return (
    <label>
      {text}
      {required && (
        <sup style={{ color: "red" }}>
          <i className="fa fa-asterisk fa-xs" aria-hidden="true" />
        </sup>
      )}
    </label>
  );
}
