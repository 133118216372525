import React from 'react'
import { useSelector } from "react-redux";
import { Route, Redirect } from 'react-router-dom';
import { userSelector } from "../reducers/userInfoSlice";
//import { isLogin } from '../services';

export default function PublicRoute({component: Component, restricted, ...rest}) {
    //const { isLogin } = useSelector(userSelector);
    return (
        <Route {...rest} render={props => (
            <Component {...props} />
        )} />
    );
}
