import axios from "axios";
import { getToken } from "../../@core/services";
import { AppConfig } from "../../AppConfig";

class UserService {
  constructor() {}

  async getInstructors(id) {
    const urlAPI = `${AppConfig.apiURL}/api/users?page=1&size=2000`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await axios.get(urlAPI, config);
    //console.log(response);
    if (response.status === 200) {
      return response.data.data.rows;
    } else return null;
  }
  // async getRoles(token) {
  //   const urlAPI = `${AppConfig.apiURL}/api/userRoles?page=1&size=2000`;
  //   const config = {
  //     headers: {
  //       Authorization: "Bearer " + token,
  //     },
  //   };
  //   // const response = await axios.get(urlAPI, config);
  //   const response = await axios.post(urlAPI, null, config);
  //   if (response.status === 200) {
  //     return response.data.data.rows;
  //   } else return null;
  // }
  async getRoles() {
    const urlAPI = `${AppConfig.apiURL}/api/userRoles?page=1&size=2000`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    // const response = await axios.get(urlAPI, config);
    const response = await axios.post(urlAPI, null, config);
    if (response.status === 200) {
      return response.data.data.rows;
    } else return null;
  }
  async searchByStudent(data) {
    const urlAPI = `${AppConfig.apiURL}/api/users/studentAutocomplete`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await axios.post(urlAPI, { student: data }, config);
    //console.log(response);
    if (response.status === 200) {
      return response.data;
    }
  }
  async register(data, reg_token) {
    const urlAPI = `${AppConfig.apiURL}/api/users/insert`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await axios.post(urlAPI, data, config);
    if (response.status === 200) {
      return { status: "00" };
    }
  }
  async insert(data) {
    //console.log('call insert api');
    const urlAPI = `${AppConfig.apiURL}/api/users/insert`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await axios.post(urlAPI, data, config);
    //console.log(response);
    if (response.status === 200) {
      return { status: "00" };
    }
  }
  async update(data) {
    const urlAPI = `${AppConfig.apiURL}/api/users/update`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await axios.post(urlAPI, data, config);
    console.log(response);
    if (response.status === 200) {
      return { status: "00" };
    }
  }
  async get(id) {
    const urlAPI = `${AppConfig.apiURL}/api/users/${id}`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await axios.get(urlAPI, config);
    console.log(response);
    if (response.status === 200) {
      return response.data;
    } else return null;
  }
  async delete(data) {
    const urlAPI = `${AppConfig.apiURL}/api/users/delete`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    let response = null;
    try {
      response = await axios.post(urlAPI, data, config);
      if (response.status === 200) {
        return { status: "00" };
      }
    } catch (error) {
      throw error;
    }
  }
  //   async checkExistsUserName(username, token) {
  //     const urlAPI = `${AppConfig.apiURL}/api/users/checkExist`;
  //     const data = {
  //       username: username,
  //     };
  //     const config = {
  //       headers: {
  //         Authorization: "Bearer " + token,
  //       },
  //     };
  //     let response = null;
  //     try {
  //       response = await axios.post(urlAPI, data, config);
  //       console.log(response);
  //       if (response.status === 200) {
  //         if (response.data === "") return false;
  //         else return true;
  //       }
  //     } catch (error) {
  //       throw error;
  //     }
  //   }
  // }
  async checkExistsUserName(username) {
    const urlAPI = `${AppConfig.apiURL}/api/users/checkExist`;
    const data = {
      username: username,
    };
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    let response = null;
    try {
      response = await axios.post(urlAPI, data, config);
      console.log(response);
      if (response.status === 200) {
        if (response.data === "") return false;
        else return true;
      }
    } catch (error) {
      throw error;
    }
  }
}

export default UserService;
